import { appConfig } from "../app";

export class ApiHelpers {
  static configuration = {
    host: appConfig.backendController,
  };

  public static async get(url: string) {
    try {
      const json = await fetch(url);
      return await json.json();
    } catch (error) {
      return false;
    }
  }

  public static async post(url: string, data: any) {
    try {
      const json = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return await json.json();
    } catch (error) {
      return false;
    }
  }

  public static async getLastExecutions() {
    return await ApiHelpers.get(this.configuration.host + "/sandwich/executions/137");
  }

  public static async getProccessingTimes() {
    return await ApiHelpers.get(this.configuration.host + "/sandwich/proccessing-time/137");
  }

  public static async getTokenData() {
    return await ApiHelpers.get(this.configuration.host + "/statistics/all-information/100");
  }

  public static async getFTBPrices() {
    return await ApiHelpers.get(this.configuration.host + "/token");
  }

  public static async preloadedFactoryRelations(factories: number[]) {
    return await ApiHelpers.post(this.configuration.host + "/preloaded-factory-relations/0xe5A8449Fb27bdC1dfEd3C94258EEa75c96e8852f/82", {
      factories,
    });
  }
}
