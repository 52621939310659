import { AnyAction } from "redux";
import { Contract } from "../../../core/app";
import { ContractData, ContractsStateController } from "../../../core/contracts";
import { AppActions, AppReducer } from "../../types";

export const contractsKey = AppReducer.CONTRACTS;

export interface ContractsState {
  [Contract.CITIES_STORAGE]: ContractData | null;
  [Contract.LP_STAKING]: ContractData | null;
  [Contract.FTB_PAIR]: ContractData | null;
  [Contract.VOLT_TOKEN]: ContractData | null;
  [Contract.CITY_RELATIONS_QUERIES]: ContractData | null;
}

export const initialState: ContractsState = {
  [Contract.CITIES_STORAGE]: null,
  [Contract.LP_STAKING]: null,
  [Contract.FTB_PAIR]: null,
  [Contract.VOLT_TOKEN]: null,
  [Contract.CITY_RELATIONS_QUERIES]: null,
};

export const contractsReducer = (state: ContractsState = initialState, action: AnyAction) => {
  switch (action.type) {
    case AppActions.SET_MULTIPLE_CONTRACT_ABI:
      return ContractsStateController.setMultipleContractsAbi(state, action.contracts);
    default:
      return state;
  }
};
