import { Contract } from "./app";

export interface CustomerToken {}

export interface ContractsAddress {}

export class AppData {
  constructor(public customerToken: CustomerToken, public contractsAddress: ContractsAddress) {}

  static buildFromTemplates(customerToken: CustomerToken, contractsAddress: ContractsAddress) {
    return new AppData(customerToken, contractsAddress);
  }
}
