export enum AppMode {
  DEV = "dev",
  PRO = "pro",
  METER = "meter",
  TEST = "test",
  TEST_METER = "test-meter",
  TEST_POLYGON = "test-polygon",
  POLYGON = "polygon",
}

export enum AppChain {
  BSC = "bsc",
  BSCT = "bsc-test",
  METER = "meter",
  METER_TEST = "meter-test",
  POLYGON_TEST = "polygon-test",
  POLYGON = "polygon",
  GANACHE = "ganache",
}

export enum Contract {
  CITIES_STORAGE = "cities-storage",
  LP_STAKING = "lp-staking",
  FTB_PAIR = "ft-pair",
  VOLT_TOKEN = "volt-token",
  CITY_RELATIONS_QUERIES = "city-relations-queries",
}

export enum AppErrorCode {
  BAD_REQUEST = "bad_request",
  INCORRECT_BLOCKCHAIN_NETWORK = "incorrect-block-chain-network",
  INVALID_PROVIDER = "invalid-provider",
  INVALID_CONTRACT_LOADING = "invalid-contract-loading",
  // NFT errors
  MINT_IS_NOT_VALID = "mint-is-not-valid",
  RESET_DEPLOYMENTS_ERROR = "reset-deployments-error",
  MINT_ERROR = "mint-error",
  ADD_EMPLOYEE_TO_FACTORY = "add-employee-to-factory-error",
  BURN_ERROR = "burn-error",
  UPGRADE_ERROR = "upgrade-error",
  GROW_ERROR = "grow-error",
  GROW_ALL_ERROR = "grow-all-error",
  CANT_GROW_ERROR = "cant-grow-error",
  CANT_GROW_ALL_ERROR = "cant-grow-all-error",
  HAVENT_UPGRADES = "havent-upgrades",
  ADD_TO_WHITELIST = "add-to-whitelist",
  ON_SAVE_MINTER_POINTS = "on-save-minter-points",
  // App errors
  USER_DENIED_TRANSACTION = "user-denied-transaction",
  INVALID_APPROVED_SPEND = "invalid-approved-spend",
  INVALID_TESTNET_REQUEST = "invalid-testnet-request",
  //Connections errors
  ADD_MODEL_ERROR = "add-model-error",
  ADD_TYPE_ERROR = "add-type-error",
  REMOVE_MODEL_ERROR = "remove-model-error",
  REMOVE_TYPE_ERROR = "remove-type-error",
  SEND_EMPLOYEE_TO_WORK_ERROR = "send-employee-to-work-error",
  //Employee staking
  SEND_EMPLOYEE_TO_STAKING = "send-employee-to-staking",
  REMOVE_EMPLOYEE_FROM_STAKING = "remove-employee-from-staking",
  TAKE_CUSTOMER_REWARDS = "take-customer-rewards",
  //Factory staking
  SEND_FACTORY_TO_STAKING = "send-factory-to-staking",
  REMOVE_FACTORY_FROM_STAKING = "remove-factory-from-staking",
  SEND_CUSTOMER_STAKING_REWARDS = "send-customer-staking-rewards",
  INVALID_POINTS = "invalid-points",
  //Token
  APPROVE_SPEND = "approved-spend",
  INCREASE_ALLOWANCE = "increase-allowance",
  //Employees
  BURN_EMPLOYEE = "burn-employee",
  APPROVE_EMPLOYEE = "approve-employee",
  SAFE_TRANSFER_FROM = "safe-transfer-from",
  //Marketplace
  SELL_ERROR = "sell-error",
  BUY_ERROR = "buy-error",
  CANCEL_SELL_ERROR = "cancel-sell-error",
  //Game
  REQUEST_TOKENS = "request-tokens",
  PLAY_ERROR = "play-error",
  //Builders
  REQUEST_ERROR = "request-error",
  //Bridge
  MINTER_REQUEST_ERROR = "request-error",
  LIMBO_REQUEST_ERROR = "limbo-error",
}

export const ULTRA_ERRORS = [AppErrorCode.INVALID_PROVIDER, AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK, AppErrorCode.INVALID_CONTRACT_LOADING];

export const appConfig = {
  mode: AppMode.METER,
  open: true,
  confirmedBlocks: 0,
  blocktimeout: 1000,
  fastTransaction: "500",
  backendController: "https://back.businessbuilders.city", // http://localhost:8806 https://back.businessbuilders.city
};

export interface AppError {
  message: string;
  code: AppErrorCode;
}

export interface Currency {
  name?: string;
  symbol?: string;
  decimals?: number;
}

export interface ChainData {
  chainId: string;
  chainName?: string;
  nativeCurrency?: Currency;
  rpcUrls?: string[];
  blockExplorerUrls?: string[] | null;
}

export type PosibleChainsType = {
  [mode in AppChain]: ChainData;
};

export type PosibleContractAddress = {
  [mode in AppMode]:
    | {
        [contract in Contract]: string | null;
      }
    | null;
};

export const artHost = {
  [AppMode.DEV]: "https://art.businessbuilders.city/static",
  [AppMode.TEST]: "https://art.businessbuilders.city/static",
  [AppMode.PRO]: "https://art.businessbuilders.city/static",
  [AppMode.METER]: "https://art.businessbuilders.city/static",
  [AppMode.TEST_METER]: "https://art.businessbuilders.city/static",
};

export const posibleContractAddress: PosibleContractAddress = {
  [AppMode.DEV]: null,
  [AppMode.TEST]: null,
  [AppMode.PRO]: null,
  [AppMode.METER]: {
    [Contract.CITIES_STORAGE]: "0xdf695e2f798672EeC4Ea64a96c39569dE4200Aa2",
    [Contract.LP_STAKING]: "0x5458DFfcc27Aae5Cc3eFd8dBfAf7405AA348545d",
    [Contract.FTB_PAIR]: "0x88cdb3e764dedcc2e3a1642957ebd513765b252a",
    [Contract.VOLT_TOKEN]: "0x8df95e66cb0ef38f91d2776da3c921768982fba0",
    [Contract.CITY_RELATIONS_QUERIES]: "0x1B5c59064EA8Bc9DAF54e11231ad61999ED13AaB",
  },
  [AppMode.TEST_METER]: null,
  [AppMode.TEST_POLYGON]: null,
  [AppMode.POLYGON]: null,
};

export const relatedModeToChain: { [key in AppChain]: AppMode } = {
  [AppChain.BSC]: AppMode.PRO,
  [AppChain.METER]: AppMode.METER,
  [AppChain.POLYGON]: AppMode.POLYGON,
  [AppChain.BSCT]: AppMode.TEST,
  [AppChain.GANACHE]: AppMode.DEV,
  [AppChain.METER_TEST]: AppMode.TEST_METER,
  [AppChain.POLYGON_TEST]: AppMode.TEST_POLYGON,
};

export const posibleChains: PosibleChainsType = {
  [AppChain.BSC]: {
    chainId: "0x38",
    chainName: AppChain.BSC,
    nativeCurrency: {
      symbol: "BNB",
      name: "Binance",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [AppChain.BSCT]: {
    chainId: "0x61",
    chainName: AppChain.BSCT,
    nativeCurrency: {
      symbol: "BNB",
      name: "Binance",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
  [AppChain.GANACHE]: {
    chainId: "0x539",
    chainName: AppChain.GANACHE,
    nativeCurrency: {
      symbol: "MTR",
      name: "Meter",
      decimals: 18,
    },
    rpcUrls: ["http://localhost:7545"],
    blockExplorerUrls: ["http://localhost:7545"],
  },
  [AppChain.METER_TEST]: {
    chainId: "0x53",
    chainName: AppChain.METER_TEST,
    nativeCurrency: {
      symbol: "MTR",
      name: "METER",
      decimals: 18,
    },
    rpcUrls: ["https://rpctest.meter.io"],
    blockExplorerUrls: ["https://scan-warringstakes.meter.io"],
  },
  [AppChain.POLYGON_TEST]: {
    chainId: "0x13881",
    chainName: AppChain.POLYGON_TEST,
    nativeCurrency: {
      symbol: "MATIC",
      name: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
  },
  [AppChain.METER]: {
    chainId: "0x52",
    chainName: AppChain.METER,
    nativeCurrency: {
      symbol: "MTR",
      name: "METER",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.meter.io"],
    blockExplorerUrls: ["https://scan.meter.io"],
  },
  [AppChain.POLYGON]: {
    chainId: "0x89",
    chainName: AppChain.POLYGON,
    nativeCurrency: {
      symbol: "MATIC",
      name: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com"],
    blockExplorerUrls: ["https://polygonscan.com"],
  },
};
