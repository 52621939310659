import { ContractsState } from "../storage/state/contracts/state";
import { Contract } from "./app";
import { UtilsHelpers } from "./helpers/utils";

import CitiesStorage from "../assets/contracts/CityRelationsStorage.json";
import LPStaking from "../assets/contracts/LPStaking.json";
import UniswapPair from "../assets/contracts/UniswapPair.json";
import Token from "../assets/contracts/Token.json";
import RelationsStorageQueries from "../assets/contracts/RelationsStorageQueries.json";

// Test Contracts

export interface NormalizedABI {
  networks: ABINetworks;
  [property: string]: any;
}

export type ABINetworkData = {
  events: any;
  links: any;
  address: string;
  transactionHash: string;
};

export type ABINetworks = {
  [id: string]: ABINetworkData;
};

export type ContractData = {
  data: NormalizedABI | null;
  contract: Contract;
};

export class ContractsStateController {
  static contractBasePath: string = "/assets/contracts/";

  static loadedContracts: any = {
    [Contract.CITIES_STORAGE]: CitiesStorage,
    [Contract.LP_STAKING]: LPStaking,
    [Contract.FTB_PAIR]: UniswapPair,
    [Contract.VOLT_TOKEN]: Token,
    [Contract.CITY_RELATIONS_QUERIES]: RelationsStorageQueries,
  };

  public static async loadContractAbiMock(path: string, callback?: (error: boolean, abiData?: any) => void) {
    fetch(path)
      .then((result) => result.json())
      .then((json: any) => {
        return callback && json ? callback(false, json) : callback ? callback(true) : null;
      })
      .catch((err) => {
        UtilsHelpers.debugger(err?.code + " " + path);
        return callback ? callback(true) : null;
      });
  }

  public static loadContractAbi(contract: Contract) {
    return ContractsStateController.loadedContracts[contract];
  }

  public static isLoaded(state: ContractsState) {
    let isValid = true;
    let keys = Object.keys(state);

    for (let i = 0; i < keys.length; i++) {
      // if (state[keys[i] as Contract] === null) isValid = false;
    }

    return isValid;
  }

  /* -------------------------------------------------------------------------- */
  /*                               ANCHOR Storage                               */
  /* -------------------------------------------------------------------------- */

  public static setContractAbi(state: ContractsState, contract: string, abiData: any): ContractsState {
    return {
      ...state,
      // [contract]: { ...state[contract as Contract], data: abiData },
    };
  }

  public static setMultipleContractsAbi(state: ContractsState, contracts: ContractData[]): ContractsState {
    let obj: any = {};

    for (let i = 0; i < contracts.length; i++) {
      obj[contracts[i].contract] = contracts[i];
    }

    return { ...state, ...obj } as ContractsState;
  }

  /* -------------------------------------------------------------------------- */
}
