import React, { createRef } from "react";
import Web3 from "web3";
import FTBLogo from "../../assets/images/FTB.png";
import BigNumber from "bignumber.js";

import { ApiHelpers } from "../../core/helpers/api";
import { SandwichBotSummary } from "../organisms/sandwich/summary";
import { NodeSummary } from "../organisms/nodes/summary";
import { ArbBotSummary } from "../organisms/sandwich/arbSummary";
import { SoonSummary } from "../organisms/sandwich/soonSummary";

import { UtilsHelpers } from "../../core/helpers/utils";

import { toast } from "react-toastify";
import { Area, AreaChart, Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
        <p>Meter: {Intl.NumberFormat().format(payload[0].payload.mp)} FTB</p>
        <p>Polygon: {Intl.NumberFormat().format(payload[0].payload.pp)} FTB</p>
      </div>
    );
  }

  return null;
};

interface MainComponentProps {
  onToggleLoader: (froce: boolean) => void;
}

interface TokensData {
  burnedTokens: any[];
  lockedTokensOnCities: any[];
  mintingLogger: any[];
  pairLogger: any[];
  poolLogger: any[];
  tokenData: any[];
  preloadedTokensData: {
    FTBMATIC: { FTB: number; MATIC: number };
    FTBMTRG: { FTB: number; MTRG: number };
    MATICUSDC: { USDC: number; MATIC: number };
    MTRGBUSD: { MTRG: number; BUSD: number };
    burnedTokens: number;
    lockedByTeam: number;
    lockedOnCities: number;
    meterRewardsPool: number;
    polygonRewardsPool: number;
    priceMTRG?: number;
    priceMATIC?: number;
    priceFTBPolygon?: number;
    priceFTBMeter?: number;
    priceFTB?: number;
    polygonLiquidity?: number;
    meterLiquidity?: number;
  };
}

interface MainComponentState {
  microRoadmap: boolean;
  miniUsersChartData: any;
  liquidityChartData: any;
  rewardsChartData: any;
  lockedOnCities: any;
  burnedChartData: any;
  allInformation: TokensData | null;
}

export class MainPage extends React.PureComponent<MainComponentProps, MainComponentState> {
  constructor(props: MainComponentProps) {
    super(props);

    this.state = {
      microRoadmap: false,
      allInformation: null,
      miniUsersChartData: [],
      liquidityChartData: [],
      rewardsChartData: [],
      lockedOnCities: [],
      burnedChartData: [],
    };
  }

  async componentDidMount() {
    this.loadTokenData();
  }

  async loadTokenData() {
    try {
      const tokensData = await ApiHelpers.getTokenData();

      if (tokensData && tokensData.error === false && tokensData.data !== null) {
        const data: TokensData = {
          burnedTokens: tokensData.data.burnedTokens,
          lockedTokensOnCities: tokensData.data.lockedTokensOnCities,
          mintingLogger: tokensData.data.mintingLogger,
          pairLogger: tokensData.data.pairLogger,
          poolLogger: tokensData.data.poolLogger,
          tokenData: tokensData.data.tokenData,
          preloadedTokensData: {
            FTBMATIC: {
              MATIC: Number(Web3.utils.fromWei(Web3.utils.hexToNumberString(tokensData.data.preloadedTokenData.FTBMATIC[0].hex))),
              FTB: Number(Web3.utils.fromWei(Web3.utils.hexToNumberString(tokensData.data.preloadedTokenData.FTBMATIC[1].hex))),
            },
            FTBMTRG: {
              MTRG: Number(Web3.utils.fromWei(Web3.utils.hexToNumberString(tokensData.data.preloadedTokenData.FTBMTRG[0].hex))),
              FTB: Number(Web3.utils.fromWei(Web3.utils.hexToNumberString(tokensData.data.preloadedTokenData.FTBMTRG[1].hex))),
            },
            MATICUSDC: {
              MATIC: Number(Web3.utils.fromWei(Web3.utils.hexToNumberString(tokensData.data.preloadedTokenData.MATICUSDC[0].hex))),
              USDC: Number(Web3.utils.fromWei(Web3.utils.hexToNumberString(tokensData.data.preloadedTokenData.MATICUSDC[1].hex))) * Math.pow(10, 12),
            },
            MTRGBUSD: {
              MTRG: Number(Web3.utils.fromWei(Web3.utils.hexToNumberString(tokensData.data.preloadedTokenData.MTRGBUSD[0].hex))),
              BUSD: Number(Web3.utils.fromWei(Web3.utils.hexToNumberString(tokensData.data.preloadedTokenData.MTRGBUSD[1].hex))),
            },
            burnedTokens: Number(tokensData.data.preloadedTokenData.burnedTokens),
            lockedByTeam: Number(Web3.utils.fromWei(Web3.utils.hexToNumberString(tokensData.data.preloadedTokenData.lockedByTeam.hex))),
            lockedOnCities: Number(tokensData.data.lockedTokensOnCities[0]._total),
            meterRewardsPool:
              Number(Web3.utils.fromWei(Web3.utils.hexToNumberString(tokensData.data.preloadedTokenData.meterRewardsPool.hex))) -
              Number(tokensData.data.lockedTokensOnCities[0]._total),
            polygonRewardsPool: Number(Web3.utils.fromWei(Web3.utils.hexToNumberString(tokensData.data.preloadedTokenData.polygonRewardsPool.hex))),
          },
        };

        data.preloadedTokensData.priceMTRG = data.preloadedTokensData.MTRGBUSD.BUSD / data.preloadedTokensData.MTRGBUSD.MTRG;

        data.preloadedTokensData.priceMATIC = data.preloadedTokensData.MATICUSDC.USDC / data.preloadedTokensData.MATICUSDC.MATIC;

        data.preloadedTokensData.priceFTBPolygon =
          (data.preloadedTokensData.FTBMATIC.MATIC / data.preloadedTokensData.FTBMATIC.FTB) * data.preloadedTokensData.priceMATIC;

        data.preloadedTokensData.priceFTBMeter =
          (data.preloadedTokensData.FTBMTRG.MTRG / data.preloadedTokensData.FTBMTRG.FTB) * data.preloadedTokensData.priceMTRG;

        data.preloadedTokensData.priceFTB = (data.preloadedTokensData.priceFTBMeter + data.preloadedTokensData.priceFTBPolygon) / 2;

        data.preloadedTokensData.polygonLiquidity = data.preloadedTokensData.FTBMATIC.FTB;

        data.preloadedTokensData.meterLiquidity = data.preloadedTokensData.FTBMTRG.FTB;

        this.setState(
          {
            allInformation: data,
          },
          () => {
            this.buildMiniUsersChart();
            this.buildLiquidityChart();
            this.buildRewardsPoolChart();
            this.buildLockedTokens();
            this.buildBurnedTokens();
          }
        );
      } else {
        toast.error("Error loading all token information");
      }
    } catch (error) {
      console.log(error);
    }
  }

  buildMiniUsersChart() {
    if ((this.state.allInformation?.burnedTokens?.length || 0) > 0 && (this.state.allInformation?.lockedTokensOnCities?.length || 0) > 0) {
      const pool = this.state.allInformation?.poolLogger || [];
      const usersData = [];

      for (let i = 0; i < pool.length; i++) {
        if (pool[i]._network === "82") {
          usersData.push({
            st: Number(Web3.utils.fromWei(pool[i]?._balance || 0)),
          });
        }
      }

      this.setState({ miniUsersChartData: usersData });
    }
  }

  buildLiquidityChart() {
    if ((this.state.allInformation?.pairLogger?.length || 0) > 0) {
      const pair = this.state.allInformation?.pairLogger || [];
      const datesObject: any = {};
      console.log(pair);
      for (let i = 0; i < pair.length; i++) {
        const date = new Date(pair[i]._time);
        const dateCode = date.getUTCDate() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCFullYear();

        if (!datesObject[dateCode]) datesObject[dateCode] = { mp: 0, pp: 0 };

        if (pair[i]._network === "82") {
          datesObject[dateCode].mp = Number(Web3.utils.fromWei(pair[i]?._tokenReserves || 0));
        }

        if (pair[i]._network === "137") {
          datesObject[dateCode].pp = Number(Web3.utils.fromWei(pair[i]?._tokenReserves || 0));
        }
      }

      this.setState({
        liquidityChartData: Object.keys(datesObject)
          .map((date) => ({ date, ...datesObject[date] }))
          .reverse(),
      });
    }
  }

  buildRewardsPoolChart() {
    if ((this.state.allInformation?.poolLogger?.length || 0) > 0) {
      const pair = this.state.allInformation?.poolLogger || [];
      const datesObject: any = {};

      for (let i = 0; i < pair.length; i++) {
        const date = new Date(pair[i]._time);
        const dateCode = date.getUTCDate() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCFullYear();

        if (!datesObject[dateCode]) datesObject[dateCode] = { mp: 0, pp: 0 };

        if (pair[i]._network === "82") {
          datesObject[dateCode].mp = Number(Web3.utils.fromWei(pair[i]?._balance || 0));
        }

        if (pair[i]._network === "137") {
          datesObject[dateCode].pp = Number(Web3.utils.fromWei(pair[i]?._balance || 0));
        }
      }

      this.setState({
        rewardsChartData: Object.keys(datesObject)
          .map((date) => ({ date, ...datesObject[date] }))
          .reverse(),
      });
    }
  }

  buildLockedTokens() {
    if ((this.state.allInformation?.lockedTokensOnCities?.length || 0) > 0) {
      const pair = this.state.allInformation?.lockedTokensOnCities || [];
      const datesObject: any = {};
      console.log(pair);

      for (let i = 0; i < pair.length; i++) {
        const date = new Date(pair[i]._time);
        const dateCode = date.getUTCDate() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCFullYear();

        if (!datesObject[dateCode]) datesObject[dateCode] = { mp: 0, pp: 0 };

        datesObject[dateCode].mp = pair[i]?._total;
      }
      this.setState({
        lockedOnCities: Object.keys(datesObject)
          .map((date) => ({ date, ...datesObject[date] }))
          .reverse(),
      });
    }
  }

  buildBurnedTokens() {
    if ((this.state.allInformation?.burnedTokens?.length || 0) > 0) {
      const pair = this.state.allInformation?.burnedTokens || [];
      const datesObject: any = {};

      for (let i = 0; i < pair.length; i++) {
        const date = new Date(pair[i]._time);
        const dateCode = date.getUTCDate() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCFullYear();

        if (!datesObject[dateCode]) datesObject[dateCode] = { mp: 0, pp: 0 };

        if (pair[i]._network === "82") {
          datesObject[dateCode].mp = Number(Web3.utils.fromWei(pair[i]?._balance || 0));
        }

        if (pair[i]._network === "137") {
          datesObject[dateCode].pp = Number(Web3.utils.fromWei(pair[i]?._balance || 0));
        }
      }

      this.setState({
        burnedChartData: Object.keys(datesObject)
          .map((date) => ({ date, ...datesObject[date] }))
          .reverse(),
      });
    }
  }

  render() {
    const totalSupply = 100000000;

    return (
      <React.Fragment>
        <div className="ct-max-container ct-title">
          <h3 className="ct-max-container">
            <img src={FTBLogo} alt="" />
            <div className="ct-text">
              <h4>BusinessBuildersNFTs</h4>
              <small>status: building ecosystems for a great future.</small>
            </div>
          </h3>
        </div>

        <div className="ct-token-info ct-max-container">
          {this.state.miniUsersChartData ? (
            <div className="ct-chart-container">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={this.state.miniUsersChartData}>
                  <Area type="monotone" dataKey="st" stroke="rgba(50,50,255,0.2)" strokeWidth={1} fill="rgba(50,50,255,0.2)" />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          ) : (
            ""
          )}

          <div className="ct-header">
            <h3>FTB Tokenomics </h3>
            <small>{(this.state.allInformation?.preloadedTokensData.priceFTB || 0).toFixed(5)} $</small>
          </div>

          <div className="ct-info-container">
            <h5>
              Rewards pools (
              {(
                (((this.state.allInformation?.preloadedTokensData.meterRewardsPool || 0) +
                  (this.state.allInformation?.preloadedTokensData.polygonRewardsPool || 0)) *
                  100) /
                totalSupply
              ).toFixed(2)}{" "}
              %)
            </h5>
            <p>
              Meter rewards pool{" "}
              <strong>({(((this.state.allInformation?.preloadedTokensData.meterRewardsPool || 0) * 100) / totalSupply).toFixed(2)} %) </strong>:{" "}
              {Intl.NumberFormat().format(this.state.allInformation?.preloadedTokensData.meterRewardsPool || 0)} FTB
            </p>
            <p>
              Polygon rewards pool{" "}
              <strong> ({(((this.state.allInformation?.preloadedTokensData.polygonRewardsPool || 0) * 100) / totalSupply).toFixed(2)} %)</strong> :{" "}
              {Intl.NumberFormat().format(this.state.allInformation?.preloadedTokensData.polygonRewardsPool || 0)}
              FTB{" "}
            </p>
          </div>

          <div className="ct-info-container">
            <h5>
              Locked tokens (
              {(
                (((this.state.allInformation?.preloadedTokensData.lockedByTeam || 0) +
                  (this.state.allInformation?.preloadedTokensData.lockedOnCities || 0)) *
                  100) /
                totalSupply
              ).toFixed(2)}{" "}
              %)
            </h5>
            <p>
              Locked tokens by team{" "}
              <strong>
                ({(((this.state.allInformation?.preloadedTokensData.lockedByTeam || 0) * 100) / totalSupply).toFixed(2)}
                %){" "}
              </strong>
              : {Intl.NumberFormat().format(this.state.allInformation?.preloadedTokensData.lockedByTeam || 0)} FTB
            </p>
            <p>
              Locked tokens in the cities system:{" "}
              <strong>
                ({(((this.state.allInformation?.preloadedTokensData.lockedOnCities || 0) * 100) / totalSupply).toFixed(2)}
                %)
              </strong>{" "}
              : {Intl.NumberFormat().format(this.state.allInformation?.preloadedTokensData.lockedOnCities || 0 || 0)} FTB
            </p>
          </div>

          <div className="ct-info-container">
            <h5>Burned tokens ({(((this.state.allInformation?.preloadedTokensData.burnedTokens || 0) * 100) / totalSupply).toFixed(2)} %)</h5>
            <p>
              By multiEmployees buys:{" "}
              <strong>
                ({((2000000 * 100) / totalSupply).toFixed(2)}
                %)
              </strong>{" "}
              : {Intl.NumberFormat().format(2000000)} FTB
            </p>
            <p>
              Using the buy and burn system:{" "}
              <strong>
                ({((((this.state.allInformation?.preloadedTokensData.burnedTokens || 0) - 2000000) * 100) / totalSupply).toFixed(2)}
                %)
              </strong>{" "}
              : {Intl.NumberFormat().format((this.state.allInformation?.preloadedTokensData.burnedTokens || 0) - 2000000)} FTB
            </p>
          </div>

          <div className="ct-info-container">
            <h5>
              Liquidity tokens (
              {(
                (((this.state.allInformation?.preloadedTokensData.polygonLiquidity || 0 || 0) +
                  (this.state.allInformation?.preloadedTokensData.meterLiquidity || 0)) *
                  100) /
                totalSupply
              ).toFixed(2)}{" "}
              %)
            </h5>
            <p className="ct-max-container">
              Polygon liquidity{" "}
              <strong>
                {" "}
                ({(((this.state.allInformation?.preloadedTokensData.polygonLiquidity || 0 || 0) * 100) / totalSupply).toFixed(2)}
                %)
              </strong>{" "}
              : {Intl.NumberFormat().format(this.state.allInformation?.preloadedTokensData.polygonLiquidity || 0 || 0)} FTB
            </p>
            <p className="ct-max-container">
              Meter liquidity{" "}
              <strong>
                ({(((this.state.allInformation?.preloadedTokensData.meterLiquidity || 0) * 100) / totalSupply).toFixed(2)}
                %){" "}
              </strong>{" "}
              : {Intl.NumberFormat().format(this.state.allInformation?.preloadedTokensData.meterLiquidity || 0)} FTB
            </p>
          </div>

          <div className="ct-info-container">
            <h5>Totals</h5>
            <p className="ct-max-container">Initial supply: {Intl.NumberFormat().format(totalSupply)} FTB</p>
            <p className="ct-max-container">
              Real supply (including burned tokens):{" "}
              {Intl.NumberFormat().format(totalSupply - (this.state.allInformation?.preloadedTokensData.burnedTokens || 0))} FTB
            </p>

            <p>
              Released tokens{" "}
              <strong>
                (
                {(
                  ((totalSupply -
                    (this.state.allInformation?.preloadedTokensData.burnedTokens || 0) -
                    (this.state.allInformation?.preloadedTokensData.meterRewardsPool || 0) -
                    (this.state.allInformation?.preloadedTokensData.polygonRewardsPool || 0) -
                    (this.state.allInformation?.preloadedTokensData.lockedByTeam || 0) -
                    (this.state.allInformation?.preloadedTokensData.lockedOnCities || 0) -
                    (this.state.allInformation?.preloadedTokensData.polygonLiquidity || 0) -
                    (this.state.allInformation?.preloadedTokensData.meterLiquidity || 0)) *
                    100) /
                  totalSupply
                ).toFixed(2)}{" "}
                %)
              </strong>
              :{" "}
              {Intl.NumberFormat().format(
                totalSupply -
                  (this.state.allInformation?.preloadedTokensData.burnedTokens || 0) -
                  (this.state.allInformation?.preloadedTokensData.meterRewardsPool || 0) -
                  (this.state.allInformation?.preloadedTokensData.polygonRewardsPool || 0) -
                  (this.state.allInformation?.preloadedTokensData.lockedByTeam || 0) -
                  (this.state.allInformation?.preloadedTokensData.lockedOnCities || 0) -
                  (this.state.allInformation?.preloadedTokensData.polygonLiquidity || 0) -
                  (this.state.allInformation?.preloadedTokensData.meterLiquidity || 0)
              )}{" "}
              FTB
            </p>
          </div>
        </div>

        <div className="ct-max-container ct-roadmap ct-mt-20">
          <div className="ct-header">
            <div className="ct-info">
              <h4>Micro-Roadmap (December - January)</h4>
              <small>Development progress</small>
            </div>
            {this.state.microRoadmap ? (
              <span onClick={() => this.setState({ microRoadmap: !this.state.microRoadmap })} className="fas fa-times"></span>
            ) : (
              <span onClick={() => this.setState({ microRoadmap: !this.state.microRoadmap })} className="fas fa-eye"></span>
            )}
          </div>

          {this.state.microRoadmap ? (
            <React.Fragment>
              <div className="ct-builds-container">
                <h4>Statistics center</h4>

                <div className="ct-scroll-container">
                  <div className="ct-builds">
                    <div className="ct-build">
                      <h4>Track everything</h4>
                      <p>
                        <small>Integrate the new NFT battles system statistics. New users, value tracking and user adquisition.</small>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="ct-progress-bar">
                  <div className="ct-bar" style={{ width: "90%" }}></div>
                  <small>90%</small>
                </div>
              </div>

              <div className="ct-builds-container">
                <h4>UniversalNFT</h4>
                <div className="ct-scroll-container">
                  <div className="ct-builds">
                    <div className="ct-build">
                      <h4>Fees to buy and burn FTB</h4>
                      <p>
                        <small>
                          Integrate all MTR fees to the FTB buying and burning system. All fee collected from the minting system and the new
                          marketplace will be used in the distribution system.
                        </small>
                      </p>
                    </div>
                    <div className="ct-build">
                      <h4>Statistics.</h4>
                      <p>
                        <small>Add minting, volume and distributions statistics on the new Statistics center.</small>
                      </p>
                    </div>

                    <div className="ct-build">
                      <h4>Tokens to earn tokens</h4>
                      <p>
                        <small>Implement the new tokens staking pools (Delayed due to the market behaviour)</small>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="ct-progress-bar">
                  <div className="ct-bar" style={{ width: "30%" }}></div>
                  <small>30%</small>
                </div>
              </div>

              <div className="ct-builds-container">
                <h4>NFTsUniverse</h4>
                <div className="ct-scroll-container">
                  <div className="ct-builds">
                    <div className="ct-build">
                      <h4>Fees to buy and burn FTB</h4>
                      <p>
                        <small>
                          Add MTR fees to all NFTs minting, cities systems and integrate the team building fees to the buying and buring system.
                        </small>
                      </p>
                    </div>

                    <div className="ct-build">
                      <h4>NFT Battles 3D arena</h4>
                      <p>
                        <small>Create NFTBattles arena in the cities universe.</small>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="ct-progress-bar">
                  <div className="ct-bar" style={{ width: "60%" }}></div>
                  <small>60%</small>
                </div>
              </div>

              <div className="ct-builds-container">
                <h4>NFT Battles</h4>

                <div className="ct-scroll-container">
                  <div className="ct-builds">
                    <div className="ct-build">
                      <h4>Deployment</h4>
                      <p>
                        <small>Deploy and integrate all battles systems on the Meter network.</small>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="ct-progress-bar">
                  <div className="ct-bar" style={{ width: "90%" }}></div>
                  <small>90%</small>
                </div>
              </div>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>

        <div className="ct-max-container ct-liquidity ct-mt-20">
          <div className="ct-header">
            <div className="ct-info">
              <h4>DEXs Liquidity</h4>
              <small>Tokens added on the DEXs liquidity</small>
            </div>
          </div>
          {this.state.liquidityChartData ? (
            <div className="ct-chart-container">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={this.state.liquidityChartData}>
                  <XAxis style={{ fontSize: "11px" }} dataKey={"date"} />
                  <Area type="monotone" dataKey="pp" name="Polygon" stroke="rgba(193, 73, 235,0.7)" strokeWidth={1} fill="rgba(193, 73, 235,0.2)" />
                  <Area type="monotone" dataKey="mp" name="Meter" stroke="rgba(50,50,255,0.7)" strokeWidth={1} fill="rgba(50,50,255,0.2)" />
                  <Tooltip content={<CustomTooltip />} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="ct-max-container ct-liquidity ct-mt-20">
          <div className="ct-header">
            <div className="ct-info">
              <h4>Rewards pools</h4>
              <small>Gameplay tokens.</small>
            </div>
          </div>
          {this.state.rewardsChartData ? (
            <div className="ct-chart-container">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={this.state.rewardsChartData}>
                  <XAxis style={{ fontSize: "11px" }} dataKey={"date"} />
                  <Area type="monotone" dataKey="pp" name="Polygon" stroke="rgba(193, 73, 235,0.7)" strokeWidth={1} fill="rgba(193, 73, 235,0.2)" />
                  <Area type="monotone" dataKey="mp" name="Meter" stroke="rgba(50,50,255,0.7)" strokeWidth={1} fill="rgba(50,50,255,0.2)" />
                  <Tooltip content={<CustomTooltip />} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="ct-max-container ct-liquidity ct-mt-20">
          <div className="ct-header">
            <div className="ct-info">
              <h4>Locked on cities</h4>
              <small>
                The users lock FTB to earn more tokens on the cities system. All users can withdraw their tokens but they wouldn't generate more
                tokens
              </small>
            </div>
          </div>
          {this.state.lockedOnCities ? (
            <div className="ct-chart-container">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={this.state.lockedOnCities}>
                  <XAxis style={{ fontSize: "11px" }} dataKey={"date"} />
                  <Area type="monotone" dataKey="mp" name="Meter" stroke="rgba(50,50,255,0.7)" strokeWidth={1} fill="rgba(50,50,255,0.2)" />
                  <Tooltip content={<CustomTooltip />} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="ct-max-container ct-liquidity ct-mt-20">
          <div className="ct-header">
            <div className="ct-info">
              <h4>Burned tokens</h4>
              <small>All tokens burned by the system.</small>
            </div>
          </div>
          {this.state.burnedChartData ? (
            <div className="ct-chart-container">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={this.state.burnedChartData}>
                  <XAxis style={{ fontSize: "11px" }} dataKey={"date"} />
                  <Area type="monotone" dataKey="mp" name="Meter" stroke="rgba(255,50,50,0.7)" strokeWidth={1} fill="rgba(255,50,50,0.2)" />
                  <Tooltip content={<CustomTooltip />} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}
