import React from "react";
import { connect } from "react-redux";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ContractsStateController } from "../core/contracts";
import { loadMultipleContractAbis } from "../storage/state/contracts/actions";
import { AppReducer, AppState } from "../storage/types";
import { BlockChain } from "../core/chain";

import { destroyBlockChainController, saveBlockChainController, setBlockChainError, setCustomer } from "../storage/state/blockChain/actions";

import { changeEmployeesPagination, saveAppData, toggleLeftNavigation, toggleLoader } from "../storage/state/app/actions";

import { BlockChainState } from "../storage/state/blockChain/state";
import { ContractsState } from "../storage/state/contracts/state";
import { appConfig, AppErrorCode, Contract } from "../core/app";
import { ApplicationState } from "../storage/state/app/state";
import { BlockChainHelpers } from "../core/helpers/chain";
import { toast, ToastContainer } from "react-toastify";
import { UtilsHelpers } from "../core/helpers/utils";
import { Customer } from "../core/customer";
import { AppData } from "../core/types";
import { NavWindow } from "./types";
import { MainPage } from "./pages/main";

import CoinFlip from "../assets/images/coin-flip.gif";
import { CitiesTracking } from "./pages/cities";

interface AppComponentProps {
  loadMultipleContractAbis: (contracts: Contract[]) => void;
  saveBlockChainController: (controller: BlockChain) => void;
  destroyBlockChainController: () => void;
  setBlockChainError: (error: AppErrorCode | null) => void;
  setCustomer: (customer: Customer) => void;
  toggleLeftNavigation: (force?: boolean) => void;
  toggleLoader: (force?: boolean) => void;
  saveAppData: (data: AppData) => void;
  appState: ApplicationState;
  contracts: ContractsState;
  blockChain: BlockChainState;
}

interface AppComponentState {
  contractLoading: boolean;
}

const mapStateToProps = (state: AppState) => {
  return {
    appState: state[AppReducer.APP],
    contracts: state[AppReducer.CONTRACTS],
    blockChain: state[AppReducer.BLOCK_CHAIN] as BlockChainState,
  };
};

const mapDispatchToProps = {
  destroyBlockChainController,
  changeEmployeesPagination,
  loadMultipleContractAbis,
  saveBlockChainController,
  toggleLeftNavigation,
  setBlockChainError,
  toggleLoader,
  setCustomer,
  saveAppData,
};

export class App extends React.Component<AppComponentProps, AppComponentState> {
  constructor(props: AppComponentProps) {
    super(props);

    this.state = {
      contractLoading: false,
    };
  }

  async componentDidMount() {}

  private async _validateAndLoadChainNetwork() {
    const connected = await BlockChainHelpers.loadWeb3();

    if (connected) {
      if (appConfig.open) {
        UtilsHelpers.debugger("Link start.");
        const windowParse = window as NavWindow;

        if (windowParse.ethereum) {
          windowParse.ethereum.on("chainChanged", async () => {
            this.props.toggleLoader(true);
            this._loadContracts();
          });

          windowParse.ethereum.on("accountsChanged", () => {
            this.props.toggleLoader(true);
            this._loadContracts();
          });

          await this._loadContracts();
        } else {
          this.props.setBlockChainError(AppErrorCode.INVALID_PROVIDER);
          UtilsHelpers.debugger("[APP] Error, Invalid provider.");
        }
      }
    }
  }

  private async _loadContracts() {
    await BlockChainHelpers.validateBlockChain((correct: boolean) => {
      if (correct) {
        this.props.loadMultipleContractAbis([
          Contract.CITIES_STORAGE,
          Contract.LP_STAKING,
          Contract.FTB_PAIR,
          Contract.VOLT_TOKEN,
          Contract.CITY_RELATIONS_QUERIES,
        ]);
      } else {
        this.props.setBlockChainError(AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK);
        this.props.destroyBlockChainController();
        this.props.toggleLoader(false);
      }
    });
  }

  componentDidUpdate() {
    if (!this.state.contractLoading && ContractsStateController.isLoaded(this.props.contracts)) {
      this.setState({ contractLoading: true }, () => {
        if (appConfig.open) this.loadBlockChain();
      });
    }
  }

  async changeToAppNetwork() {
    await BlockChainHelpers.reloadOrChangeNetwork();
  }

  loadBlockChain() {
    console.log("load blockchain");
    try {
      let blockChain = new BlockChain();

      blockChain.principalListener.on(AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK, () => {
        this.props.setBlockChainError(AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK);
      });

      blockChain.loadBlockChainData(this.props.contracts, async (err: AppErrorCode | null) => {
        if (err) this.props.setBlockChainError(err);
        this.props.saveBlockChainController(blockChain);
        this.loadCustomerData(true);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async loadCustomerData(inTheEnd: boolean = false) {
    try {
      if (this.props.blockChain.controller) {
        let { customer, appData, error } = await Customer.loadCustomerData(this.props.blockChain.controller);

        if (error) this.props.setBlockChainError(error);
        else {
          if (customer) this.props.setCustomer(customer);
          if (appData) this.props.saveAppData(appData);
        }

        if (inTheEnd) this.props.toggleLoader(false);
      }
    } catch (error) {
      toast.error("Invalid user loading, please report the error in the telegram group.");
    }
  }

  componentWillUnmount() {
    this.props.destroyBlockChainController();
  }

  render() {
    return (
      <BrowserRouter>
        <div className="ct-app-container">
          {appConfig.open ? (
            <Switch>
              <Route exact path="/">
                <MainPage onToggleLoader={(force) => this.props.toggleLoader(force)}></MainPage>
              </Route>

              <Route exact path="/cities">
                <CitiesTracking
                  blockchain={this.props.blockChain}
                  onLoadBlockchainData={() => this._validateAndLoadChainNetwork()}
                  onToggleLoader={(force) => this.props.toggleLoader(force)}
                ></CitiesTracking>
              </Route>
            </Switch>
          ) : (
            ""
          )}

          <ToastContainer theme="dark" limit={3} />
        </div>
      </BrowserRouter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
