import { ContractBaseModel } from "../abstracts/contract";
import { AbstractUniswapPair } from "../abstracts/UniswapPair";

export class UniswapPair extends ContractBaseModel<AbstractUniswapPair> {
  getReserves() {
    return this.contract.getReserves().call(this.config);
  }

  totalSupply() {
    return this.contract.totalSupply().call(this.config);
  }
}
