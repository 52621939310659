import { appConfig, AppMode } from "../app";
import MTRGLogo from "../../assets/images/MTRG.png";
import FTBLogo from "../../assets/images/FTB.png";
import DailyCoinsLogo from "../../assets/images/coin-flip.png";
import BigNumber from "bignumber.js";

export class UtilsHelpers {
  static toHexadecimal(number: string) {
    if (!isNaN(Number(number))) return parseInt(number, 16).toString();
    else console.log("[UTILS] To Hexadecimal error.");
  }

  static normalizeWei(wei: string) {
    return !isNaN(Number(wei)) ? Number(wei) / Math.pow(10, 18) : NaN;
  }

  static getTokenImageByAddress(address: string) {
    console.log(address);
    switch (address.toLocaleLowerCase()) {
      case "0x6cfe9adad5215195c1aa9755daed29360e6ab986":
        return FTBLogo;
      case "0x228ebbee999c6a7ad74a6130e81b12f9fe237ba3":
        return MTRGLogo;
      default:
        return DailyCoinsLogo;
    }
  }

  static debugger(log: string) {
    if (
      appConfig.mode === AppMode.DEV ||
      appConfig.mode === AppMode.TEST ||
      appConfig.mode === AppMode.TEST_METER ||
      appConfig.mode === AppMode.METER ||
      appConfig.mode === AppMode.TEST_POLYGON
    ) {
      console.warn("[DEBUG] " + log);
    }
  }

  static parseSeconds(timeInSeconds: number) {
    const minutes = timeInSeconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;

    return timeInSeconds < 60
      ? timeInSeconds.toFixed() + " Seconds"
      : minutes < 60
      ? minutes.toFixed() + " Minutes"
      : hours < 48
      ? hours.toFixed() + " Hours"
      : days.toFixed() + " Days";
  }

  static timestampToHours(miliseconds: number) {
    return (miliseconds / 1000 / 60 / 60).toFixed(1);
  }

  static parseMoney(money: number) {
    return Intl.NumberFormat().format(money);
  }

  static shuffle(array: any[]) {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  static validateFiles(files: File[], validType: string[]) {
    let isValid = true;

    for (let i = 0; i < files.length; i++) {
      let validated = false;

      for (let j = 0; j < validType.length; j++) {
        if (files[i].type === validType[j]) {
          validated = true;
          break;
        }
      }

      if (validated === false) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  static validateFilesSize(files: File[], maxSize: number) {
    let isValid = true;

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxSize) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  static formatBigNumbers(n: number) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  }

  static fixWithoutRounding(n: number, d: number) {
    return (Math.floor(n * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
  }

  static toDecimals(value: BigNumber, fromDecimal: number, toDecimals = 18) {
    return value.multipliedBy(10 ** (toDecimals - fromDecimal));
  }
}
