import React from "react";
import { ResponsiveContainer, Treemap } from "recharts";
import Web3 from "web3";
import FTBLogo from "../../assets/images/FTB.png";
import { ApiHelpers } from "../../core/helpers/api";
import { BlockChainState } from "../../storage/state/blockChain/state";

const COLORS = ["#8889DD", "#9597E4", "#8DC77B", "#A5D297", "#E2CF45", "#F8C12D"];

class CustomizedContent extends React.PureComponent {
  render() {
    const { root, depth, x, y, width, height, index, payload, rank, name } = this.props as any;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: COLORS[Math.floor((index / root.children.length) * 6)],
            stroke: "#fff",
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
          }}
        />

        {depth === 2 ? (
          <text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" fill="#fff" fontSize={8}>
            {name}
          </text>
        ) : null}
      </g>
    );
  }
}

interface MainComponentProps {
  onToggleLoader: (froce: boolean) => void;
  onLoadBlockchainData: () => void;
  blockchain: BlockChainState;
}

interface MainComponentState {
  relations: any[];
  tokenData: any;
  lpsUserData: any;
  reservesData: any;
  totalVoltOnRewards: string;
  lpsTotalSupply: string;
  treemapData: any[];
}

export class CitiesTracking extends React.Component<MainComponentProps, MainComponentState> {
  constructor(props: MainComponentProps) {
    super(props);

    this.state = {
      relations: [],
      tokenData: null,
      lpsUserData: null,
      reservesData: null,
      treemapData: [],
      totalVoltOnRewards: "0",
      lpsTotalSupply: "0",
    };
  }

  componentDidMount(): void {
    this.props.onLoadBlockchainData();
  }

  async preloadRelationsInformation() {
    if (this.props.blockchain.controller?.selectedAccount && this.props.blockchain.controller.cityRelationsStorage?.address) {
      const relations = await this.props.blockchain.controller.cityRelationsStorage?.getCustomerRelations(
        this.props.blockchain.controller?.selectedAccount
      );

      let loadedAdditions = null;

      if (relations && relations.length > 20) {
        loadedAdditions = await new Promise((res) => {
          if (this.props.blockchain.controller?.cityRelationsStorage?.address && this.props.blockchain.controller?.selectedAccount) {
            let separatedRelations = [];
            let generatedArray = [];
            let requests = [];

            for (let i = 0; i < relations.length; i++) {
              generatedArray.push(relations[i]);
              if (i % 15 === 0 && i !== 0) {
                separatedRelations.push(generatedArray);
                generatedArray = [];
              }
            }

            for (let i = 0; i < separatedRelations.length; i++) {
              requests.push(
                this.props.blockchain.controller?.relationsStorageQueries?.getManyFactoryAdditions(
                  separatedRelations[i],
                  this.props.blockchain.controller.cityRelationsStorage?.address,
                  this.props.blockchain.controller?.selectedAccount
                )
              );
            }

            Promise.all(requests)
              .then((requestsData) => {
                if (requestsData && Array.isArray(requestsData)) {
                  res(requestsData.flat());
                }
              })
              .catch(() => res([]));
          }
        });
      } else {
        loadedAdditions = await this.props.blockchain.controller.relationsStorageQueries?.getManyFactoryAdditions(
          relations,
          this.props.blockchain.controller.cityRelationsStorage?.address,
          this.props.blockchain.controller?.selectedAccount
        );
      }

      if (loadedAdditions.length > 0) {
        this.setState({
          relations: loadedAdditions,
          treemapData: [
            {
              name: "City #1",
              children:
                loadedAdditions.length > 0
                  ? loadedAdditions.map((relation: any, index: number) => {
                      return { name: "#" + (index + 1), size: Number(Web3.utils.fromWei(relation.entryPayment)) };
                    })
                  : [],
            },
          ],
        });
      }
    }
  }

  async preloadLPInformation() {
    if (this.props.blockchain.controller?.lpStaking && this.props.blockchain.controller.selectedAccount) {
      const userData = await this.props.blockchain.controller.lpStaking.getUserData();
      const reserves = await this.props.blockchain.controller.ftbPair?.getReserves();
      const lpsTotalSupply = await this.props.blockchain.controller.ftbPair?.totalSupply();
      const totalVoltOnRewards = await this.props.blockchain.controller.voltToken?.balanceOf("0x9f405E89D2dAd3690eCeDda4fF00B4d29A6f9F2F");
      const tokenData = await ApiHelpers.getFTBPrices();
      this.setState({ lpsUserData: userData, reservesData: reserves, totalVoltOnRewards, lpsTotalSupply, tokenData });
    }
  }

  componentDidUpdate(prevProps: Readonly<MainComponentProps>, prevState: Readonly<MainComponentState>, snapshot?: any): void {
    if (!prevProps.blockchain.controller?.selectedAccount && this.props.blockchain.controller?.selectedAccount) {
      this.preloadRelationsInformation();
      this.preloadLPInformation();
    }
  }

  downloadCSV() {
    if (this.state.relations && this.state.relations.length > 0) {
      const tokenPriceAverage = this.state.tokenData?.polygonPrice ? (this.state.tokenData?.polygonPrice + this.state.tokenData?.meterPrice) / 2 : 0;

      const rows = [["ID", "TOKENS (FTB)", "VALUE (USD)"]];
      let totalTokens = 0;
      let totalValue = 0;

      for (let i = 0; i < this.state.relations.length; i++) {
        const parsedEntry = Number(Web3.utils.fromWei(this.state.relations[i].entryPayment));
        totalTokens += parsedEntry;
        totalValue += parsedEntry * tokenPriceAverage;
        rows.push([("ID: " + (i + 1)).toString(), parsedEntry.toFixed(2) + " FTB", (parsedEntry * tokenPriceAverage).toFixed(2) + " USD"]);
      }

      rows.push(["TOTAL", totalTokens.toFixed(2) + " FTB", totalValue.toFixed(2) + " USD"]);

      let csvContent = "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "my_data.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    }
  }

  render() {
    const selectedAccount = this.props.blockchain.controller?.selectedAccount;
    const tokenPriceAverage = this.state.tokenData?.polygonPrice ? (this.state.tokenData?.polygonPrice + this.state.tokenData?.meterPrice) / 2 : 0;
    const lockedLPs = this.state.lpsUserData?.locked?.amount || "0";
    const FTBOnReserves = Number(Web3.utils.fromWei(this.state.reservesData?.reserve1 || "0"));
    const MTRGOnReserves = Number(Web3.utils.fromWei(this.state.reservesData?.reserve0 || "0"));
    const userLPsOnReserve = Number(Web3.utils.fromWei(lockedLPs || "0"));
    const LPsTotalSupply = Number(Web3.utils.fromWei(this.state.lpsTotalSupply || "0"));
    const userFTBReserves = (FTBOnReserves * userLPsOnReserve) / LPsTotalSupply;
    const userMTRGReserves = (MTRGOnReserves * userLPsOnReserve) / LPsTotalSupply;
    const totalVoltOnRewards = 30000;
    const usersTotalLPs = Number(Web3.utils.fromWei(this.state.lpsUserData?.totalLPs || "0")) - 64674;
    const userRealPower = (userLPsOnReserve * 100) / usersTotalLPs;
    const realRewards = (userRealPower / 100) * totalVoltOnRewards;
    let lockedOnRelations = 0;

    for (let i = 0; i < this.state.relations.length; i++) {
      lockedOnRelations += Number(Web3.utils.fromWei(this.state.relations[i].entryPayment));
    }

    return (
      <div className="ct-cities-tracking">
        <div className="ct-max-container ct-title">
          <h3>
            <img src={FTBLogo} alt="" />
            <div className="ct-text">
              <h4>BusinessBuildersNFTs</h4>
              <small>status: building ecosystems for a great future.</small>
            </div>
          </h3>
          <div className="ct-actions">
            {!!selectedAccount ? (
              <span>...{selectedAccount.substring(selectedAccount.length - 7, selectedAccount.length - 1)}</span>
            ) : (
              <button className="ct-main-button" onClick={() => this.props.onLoadBlockchainData()}>
                Connect
              </button>
            )}
          </div>
        </div>
        <div className="ct-max-container ct-cities-statistics">
          <span>
            <h4>Locked on relations</h4>
            <small>{Intl.NumberFormat().format(lockedOnRelations)} FTB</small>
            <small>{(lockedOnRelations * tokenPriceAverage).toFixed(2)} USD</small>
            <p>On The cities system</p>
          </span>
          <span>
            <h4>Locked LPs</h4>
            <small>{Intl.NumberFormat().format(userLPsOnReserve)} FTB/MTRG</small>
            <small>{Intl.NumberFormat().format(userFTBReserves)} FTB</small>
            <small>{Intl.NumberFormat().format(userMTRGReserves)} MTRG</small>
            <p>On UNINFT</p>
          </span>
          <span>
            <h4>Locked Volt rewards</h4>
            <small>{Intl.NumberFormat().format(userRealPower)}% power</small>
            <small>{Intl.NumberFormat().format(realRewards)} Volt</small>
            <p>On UNINFT</p>
          </span>
        </div>
        <div className="ct-relations-show ct-max-container">
          <div className="ct-relations-title">
            <h4>Active Relations</h4>
            <div className="ct-actions">
              <span onClick={() => this.downloadCSV()} className="fas fa-download"></span>
              <span className="fab fa-js"></span>
            </div>
          </div>
          {this.state.treemapData && this.state.treemapData.length > 0 ? (
            <div className="ct-treemap-chart">
              <ResponsiveContainer width="100%" height="100%">
                <Treemap
                  isAnimationActive={false}
                  width={400}
                  height={200}
                  data={this.state.treemapData}
                  dataKey="size"
                  stroke="#fff"
                  fill="#8884d8"
                  content={<CustomizedContent />}
                />
              </ResponsiveContainer>
            </div>
          ) : (
            ""
          )}

          <div className="ct-relations-container">
            {this.state.relations.length > 0
              ? this.state.relations.map((relation, index) => {
                  const parsedEntry = Number(Web3.utils.fromWei(relation.entryPayment));

                  return (
                    <div key={index} className="ct-relation-container">
                      <h4>Relation #{index + 1}</h4>
                      <small>Value locked: {Intl.NumberFormat().format(parsedEntry)} FTB</small>
                      <small>USD locked: {(parsedEntry * tokenPriceAverage).toFixed(2)} USD</small>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    );
  }
}
